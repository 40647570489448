// 文章栏目
import request from '@/util/request.js'

// 文章栏目详情
export function ContentInfo(id) {
	return request({
		method: 'get',
		url: `/api/content/info/${id}`
	})
}

// 不分页获取文章栏目
export function ContentList(menuId='',parentMenuId='') {
	return request({
		method: 'get',
		url: `/api/content/list?menuId=${menuId}&parentMenuId=${parentMenuId}`
	})
}

// 分页获取文章栏目
export function ContentPage(menuId='',parentMenuId='',pageNum='',pageSize='') {
	return request({
		method: 'get',
		url: `/api/content/page?menuId=${menuId}&parentMenuId=${parentMenuId}&pageNum=${pageNum}&pageSize=${pageSize}`
	})
}

// 随机获取文章栏目（相关阅读）
export function ContentRandom(id,num) {
	return request({
		method: 'get',
		url: `/api/content/random?id=${id}&num=${num}`
	})
}
