<!-- 新闻中心 -->
<template>
	<div class="xinWenZhongXin" id="xinWenZhongXin">
		<!-- 主体 -->
		<div class="page-content">
			<h-img class="pic-001" :src="$store.getters.getMenuItem('xinWenZhongXin').frontImg"></h-img>
			<div class="main-content" id="scrollIntoView">
				<div class="nav-list">
					<div
						class="item"
						:class="menuItem_xinWenZhongXin_item.menuId == menuItem_xinWenZhongXin_active_menuId ? 'active' : 'normal'"
						v-for="(menuItem_xinWenZhongXin_item, index) in menuItem_xinWenZhongXin.children"
						:key="index"
						@click="menuItem_xinWenZhongXin_item_click(menuItem_xinWenZhongXin_item, index)"
					>
						{{ menuItem_xinWenZhongXin_item.menuName }}
					</div>
				</div>
				<div class="news-list">
					<div class="item" v-for="(item, index) in xinWenZhongXin" :key="index" @click="$router.push({ name: 'xinWenXiangQing', params: { id: item.id } })">
						<h-img class="cover" hoverClass="scale-1_1" :src="item.contentImg"></h-img>
						<div class="content">
							<div class="title">{{ item.contentTitle }}</div>
							<div class="body-content">{{ item.contentDescription }}</div>
							<div class="date">{{ getDateTime(item.createTime) }}来源：作者{{ item.contentSource }}</div>
						</div>
					</div>
				</div>
				<div class="paging-device">
					<el-pagination
						background
						layout="prev, pager, next"
						prev-text="上一页"
						next-text="下一页"
						:total="total"
						:current-page.sync="currentPage"
						@current-change="currentChange"
					></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ContentPage } from '@/apis/wenZhangLanMu.js';
export default {
	data() {
		return {
			menuItem_xinWenZhongXin: {},
			menuItem_xinWenZhongXin_active_menuId: '',
			xinWenZhongXin: [],
			currentPage: 1,
			total: 0,
			pageSize: 0
		};
	},
	watch: {
		erJiMenu_path(newval, oldval) {
			if (this.menuItem_xinWenZhongXin.children.some(val => newval == val.path)) {
				document.querySelector('#xinWenZhongXin').scrollIntoView({
					behavior: 'smooth'
				});
				this.getMenuListForXinWenZhongXin();
			}
		}
	},
	created() {
		this.pageSize = this.isMobile ? 6 : 6;
		this.getMenuListForXinWenZhongXin();
	},
	mounted() {
		new WOW({
			boxClass: 'wow',
			animateClass: 'animated',
			offset: this.isMobile ? 0 : 150,
			mobile: true,
			live: false
		}).init();

		if (this.isMobile) {
			this.$bus.$on('onReachBottom', data => {
				console.log('数据', data);
				console.log('this.xinWenZhongXin.length: ', this.xinWenZhongXin.length);
				console.log('this.pageSize: ', this.pageSize);
				console.log('this.total: ', this.total);
				if (this.xinWenZhongXin.length && this.currentPage * this.pageSize < this.total) {
					this.currentPage++;
					this.getContentPageForXinWenZhongXin();
				} else {
					this.$message({
						message: '没有更多了~',
						center: true,
						offset: window.screen.height / 2
					});
				}
			});
		}
	},
	beforeDestroy() {
		this.$bus.$off('onReachBottom');
	},
	methods: {
		getMenuListForXinWenZhongXin() {
			this.menuItem_xinWenZhongXin = { ...this.$store.getters.getMenuItem('xinWenZhongXin') };

			this.menuItem_xinWenZhongXin.children = [
				{
					menuName: '全部',
					menuId: this.menuItem_xinWenZhongXin.menuId
				},
				...this.menuItem_xinWenZhongXin.children
			];

			if (this.erJiMenu_path) {
				let erJiMenu_item = this.$store.getters.getMenuItem(this.erJiMenu_path);
				let find_item = this.menuItem_xinWenZhongXin.children.find(val => val.menuId == erJiMenu_item.menuId);

				if (find_item) {
					this.menuItem_xinWenZhongXin_active_menuId = find_item.menuId;
					this.getContentPageForXinWenZhongXin();
				} else {
					this.menuItem_xinWenZhongXin_active_menuId = this.menuItem_xinWenZhongXin.children[0].menuId;
					this.getContentPageForXinWenZhongXin();
				}
			} else {
				this.menuItem_xinWenZhongXin_active_menuId = this.menuItem_xinWenZhongXin.children[0].menuId;
				this.getContentPageForXinWenZhongXin();
			}
		},
		getContentPageForXinWenZhongXin() {
			if (this.menuItem_xinWenZhongXin_active_menuId == this.menuItem_xinWenZhongXin.menuId) {
				ContentPage('', this.menuItem_xinWenZhongXin_active_menuId, this.currentPage, this.pageSize).then(res => {
					console.log('this.pageSize: ', this.pageSize);
					this.total = res.total;
					if (!this.isMobile) {
						this.xinWenZhongXin = res.data;
					} else {
						this.xinWenZhongXin = this.xinWenZhongXin.concat(res.data);
					}
					console.log('this.xinWenZhongXin: ', this.xinWenZhongXin);
				});
			} else {
				ContentPage(this.menuItem_xinWenZhongXin_active_menuId, '', this.currentPage, this.pageSize).then(res => {
					this.total = res.total;
					if (!this.isMobile) {
						this.xinWenZhongXin = res.data;
					} else {
						this.xinWenZhongXin = this.xinWenZhongXin.concat(res.data);
					}
				});
			}
		},
		menuItem_xinWenZhongXin_item_click(item, index) {
			if (this.menuItem_xinWenZhongXin_active_menuId == item.menuId) {
				return;
			} else {
				this.menuItem_xinWenZhongXin_active_menuId = item.menuId;
				this.xinWenZhongXin = []
				this.currentPage = 1;
				this.getContentPageForXinWenZhongXin();
			}
		},
		currentChange(e) {
			this.currentPage = e;
			this.getContentPageForXinWenZhongXin();

			document.querySelector('#scrollIntoView').scrollIntoView({
				behavior: 'smooth'
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import './xinWenZhongXin.scss';
@import './xinWenZhongXinMedia.scss';
</style>
